Vue.component('carModalDetailComponent', {
    props:['item','backToList'],
    template: `
              <div :key="item.Code" id="carItemDetail" class="cart-item-container" >
                      <div class="title">
                        <h4>{{tr("Item Detail")}}</h4>
                      </div>
                      <div class="row">
                            <div class="col m4 s12"> 
                                <itemSliderComponent :item="item" :container="true" :showCarusel="true"></itemSliderComponent>
                            </div>
                            <div class="col m8 s12">
                               <h6>{{item.Name}} </h6>
                               <p>{{item.Comment}} </p>
                               <button class="btn green btn-flat white-text" @click="backToList(item)">{{tr('Back to List')}}</button>
                            </div>
                      </div> 
              </div>`,
});
